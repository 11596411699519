<template>
  <b-row>
    <b-col>
      <tabela-repeticao
        v-model="pendencias"
        :somente-visualizacao="true"
        :colunas="colunas"
        class="tabela-pendencias"
      >
        <template #visualizacao="{slotScope: {valor}}">
          <div
            class="ml-50"
          >
            {{ valor }}
          </div>
        </template>
      </tabela-repeticao>
    </b-col>
  </b-row>

</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useColunas from './useColunas'

export default {
  name: 'VisualizacaoPendencias',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    pendencias: {
      type: Array,
      required: true,
    },
    grupoPendencia: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const colunas = ref([])

    watch(() => props.grupoPendencia, () => {
      let baseIndisponivel
      baseIndisponivel = false

      props.pendencias.forEach(pendencia => {
        if (pendencia.baseIndisponivel) {
          baseIndisponivel = true
        }
      })
      colunas.value = useColunas(props.grupoPendencia, baseIndisponivel).colunas
    })

    if (props.grupoPendencia) {
      let baseIndisponivel
      baseIndisponivel = false

      props.pendencias.forEach(pendencia => {
        if (pendencia.baseIndisponivel) {
          baseIndisponivel = true
        }
      })

      colunas.value = useColunas(props.grupoPendencia, baseIndisponivel).colunas
    }

    return {
      colunas,
    }
  },
}
</script>
