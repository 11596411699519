/* eslint-disable no-nested-ternary */
export default function useMenus(tipoPendencia, baseIndisponivel) {
  const divergenciaGfipGps = [
    {
      key: 'competencia', label: 'Competência',
    },
    {
      key: 'fpas', label: 'FPAS',
    },
    {
      key: 'rubica', label: 'Rubrica',
    },
    {
      key: 'valor', label: 'Valor (R$)',
    },
    { key: 'id', thClass: 'd-none', tdClass: 'd-none' }]

  const omissaoDeclaracao = [
    {
      key: 'pendenciaDescricao', label: 'Pendência', tdClass: 'col-2',
    }, {
      key: 'cnpjCei', label: 'CNPJ/CEI', tdClass: 'col-3',
    }, {
      key: 'apuracao', label: 'Apuração',
    }]

  const dividaAtiva = [
    {
      key: 'inscricao', label: 'Inscrição/Conta',
    }]

  const debitoSief = [
    { key: 'receita', label: 'Receita' },
    { key: 'exercicio', label: 'PA/Exercicio' },
    { key: 'dataVencimento', label: 'Data Vencimento' },
    { key: 'valorOriginal', label: 'Valor Original (R$)' },
    { key: 'saldoDevedor', label: 'Saldo Devedor (R$)' },
    { key: 'situacao', label: 'Situacao' },
  ]

  const parcelamentoParcsnParcmei = [
    { key: 'tipo', label: 'Tipo' },
    { key: 'situacao', label: 'Situação' },
    { key: 'nmrParcAtradas', label: 'Qtd. Atrasadas' },
  ]

  const debitoSicob = [
    { key: 'debito', label: 'Débito', tdClass: 'col-2' },
    { key: 'situacao', label: 'Situação' },
  ]

  const parcelamentoSipade = [
    { key: 'nmrParcAtrasadas', label: 'Parcelas em Atraso' },
    { key: 'receita', label: 'Receita' },
    { key: 'processo', label: 'Processo' },
    { key: 'situacao', label: 'Situacao' },
  ]

  const processoFiscalSief = [
    { key: 'processo', label: 'Processo' },
  ]

  const processoArrolamentoBens = [
    { key: 'localizacao', label: 'Localização' },
    { key: 'processo', label: 'Processo' },
  ]

  const parcelamentoSiefpar = [
    { key: 'nmrParcelamento', label: 'Parcelamento' },
    { key: 'tipoParcelamento', label: 'Tipo' },
    { key: 'nmrParcAtrasadas', label: 'Parecelas em Atraso' },
    { key: 'valor', label: 'Valor' },
  ]

  const irregularidadeCadastral = [
    { key: 'tipo', label: 'Tipo' },
    { key: 'situacao', label: 'Situação' },
  ]

  let colunas = null

  if (baseIndisponivel && tipoPendencia.toLowerCase() !== 'dividaativa') {
    colunas = [{ key: 'baseIndisponivelText', label: ' ' }]
  } else {
    switch (tipoPendencia.toLowerCase()) {
      case 'gfipgps':
        colunas = divergenciaGfipGps
        break
      case 'dividaativa':
        colunas = dividaAtiva
        break
      case 'omissaodeclaracao':
        colunas = omissaoDeclaracao
        break
      case 'debitosief':
        colunas = debitoSief
        break
      case 'parcsnparcmei':
        colunas = parcelamentoParcsnParcmei
        break
      case 'debitosicob':
        colunas = debitoSicob
        break
      case 'parcelamentosipade':
        colunas = parcelamentoSipade
        break
      case 'processofiscalsief':
        colunas = processoFiscalSief
        break
      case 'processoarrolamentobens':
        colunas = processoArrolamentoBens
        break
      case 'parcelamentosiefpar':
        colunas = parcelamentoSiefpar
        break
      case 'irregularidadecadastral':
        colunas = irregularidadeCadastral
        break
      default:
        break
    }
  }

  return {
    colunas,
  }
}
